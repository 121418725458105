<template>
  <section>
    <p>NOSSA <strong>GALERIA</strong></p>
  </section>
  <section>
    <ul class="g2">
      <li><img src="@/assets/galeria/img01.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img02.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img03.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img04.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img05.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img06.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img07.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img08.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img09.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img10.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img11.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img12.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img13.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img14.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img15.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img16.webp" width="0" height="0" alt=""></li>
      <li><img src="@/assets/galeria/img17.webp" width="0" height="0" alt=""></li>
    </ul>
  </section>
</template>
<style lang="scss" scoped>
/* p{
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 35px;
    font-weight: 400;
} */
img{
  max-width: 100%!important;
  min-width: 100%!important;
  width:auto ;
  height: auto;
}
ul{
  margin: 0 auto!important;
  padding: 0!important;
}
.g2{
  display: grid;
  max-width: 90%!important;
  grid-template-columns: repeat(4,calc(100%/4));
  column-gap: 5px;
  row-gap: 2.5px;
  @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-template-columns: repeat(2,calc(100%/2));
  }
}
</style>
